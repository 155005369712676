"use client";

import { useEffect } from "react";

import { getLogger } from "@/lib/di";
import ErrorPage from "@components/ErrorPage/ErrorPage";

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	useEffect(() => {
		const logger = getLogger();
		// Log the error to an error reporting service
		logger.error(error);
	}, [error]);

	return (
		<ErrorPage
			error={500}
			redirectTo={"/"}
			errorTitle='Error'
			errorDescription={error.message}
		>
			<br />
			<button
				onClick={
					// Attempt to recover by trying to re-render the segment
					() => reset()
				}
			>
				Try again
			</button>
		</ErrorPage>
	);
}
