import Image from "next/image";
import Link from "next/link";

import { brokenLink } from "@icons/index";
import { childrensLogo } from "@logos/index";

export default function ErrorPage({
	error,
	redirectTo,
	errorTitle,
	errorDescription,
	children,
}: any) {
	return (
		<div className='error-page'>
			<section>
				<div className='error-header'>
					<Link href={redirectTo}>
						<Image
							src={childrensLogo}
							alt="Children's Logo"
							width={272}
							height={68}
						/>
					</Link>
				</div>
				<div className='error-body'>
					<span className='section-icon'>
						<Image src={brokenLink} alt='broken link' />
					</span>
					<h1>{error}</h1>
					<h4>{errorTitle}</h4>
					{errorDescription}
					{children}
				</div>
			</section>
		</div>
	);
}
